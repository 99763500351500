import {
  AuctionInfo,
  BasicAttributeValue,
  BasicCategory,
  Category,
  CategoryPayment,
  CategoryPreview,
  Listing,
  ListingImage,
  RootCategory,
  ShippingInfo,
  Variant,
} from 'src/api/v1-api/helpers';
import { makeHeaders, apiRequest } from './fetchUtils';
import { getValueObjectValue, getSingleValueFromSelectValueObjects } from './attributeUtils';
import { utcISOToLocalDateISO } from './common';
import { DEFAULT_IMAGE } from 'src/constants/config';
import React from 'react';
import slugify from 'slugify';

export const validateAddress = async (shippingInfo: ShippingInfo | null): Promise<string> => {
  const url = `${process.env.NEXT_PUBLIC_API_HOST}/shippo/address/validate/`;
  let message = '';

  try {
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(shippingInfo),
      headers: makeHeaders(),
    });
    if (!response.ok) {
      throw new Error(await response.text());
    }
  } catch (error) {
    console.error(error);
    message = "We couldn't find that address. Please enter valid shipping info.";
  }

  return message;
};

export const getAvailableVariants = (variants: Variant[] | undefined) => {
  return (variants ?? []).filter((variant) =>
    (variant.variant_attribute_values ?? []).every(
      (item) => !!item?.attribute?.name && getValueObjectValue(item.value_object) !== null,
    ),
  );
};

export const filterVariants = (
  availableVariants: Variant[],
  selectedAttributeValues: {
    [key: string]: string | number;
  },
) => {
  return availableVariants.filter((variant) => {
    const invalidAttributeValues = (variant.variant_attribute_values ?? []).filter((item) => {
      const selectedValue = selectedAttributeValues[item.attribute.name];

      let actualValue: string | number | null;
      if (item.value_object?.values) {
        // TODO(Sean): Fix this type
        // @ts-ignore
        actualValue = getSingleValueFromSelectValueObjects(item.value_object);
      } else {
        actualValue = getValueObjectValue(item.value_object)[0].value;
      }

      return selectedValue === undefined ? false : actualValue !== selectedValue;
    });

    return invalidAttributeValues.length === 0;
  });
};

export const getSelectedVariant = (
  variants: Variant[] | undefined,
  filteredVariants: Variant[],
  selectedAttributeValues: {
    [key: string]: string | number;
  },
  attributeOptions: {
    [key: string]: (string | number)[];
  },
) => {
  let selectedVariant = variants?.[0];
  if (variants && variants.length > 1) {
    selectedVariant =
      filteredVariants.length === 1 &&
      Object.keys(selectedAttributeValues).length === Object.keys(attributeOptions).length
        ? filteredVariants[0]
        : undefined;
  }
  return selectedVariant;
};

export const isListingActiveOrAfter = (status: string | null | undefined) => {
  if (!status) return false;
  const activeOrAfterStatuses = ['active', 'sold', 'expired', 'deleted', 'admin_deactivated'];
  return status in activeOrAfterStatuses;
};

export const determineShowListing = (status: string, showInactive: boolean, showSold: boolean) => {
  if (status === 'active') return true;
  if (status === 'sold' && (showSold || showInactive)) return true;
  if (showInactive) return true;
  return false;
};

export const determineAuctionInfo = (auctionInfos: AuctionInfo[] | undefined) => {
  if (!auctionInfos) return undefined;
  let currInfo: AuctionInfo | undefined = undefined;
  if (auctionInfos.length === 1) {
    currInfo = auctionInfos[0];
  } else {
    for (const auctionInfo of auctionInfos) {
      if (!auctionInfo.deleted) {
        currInfo = auctionInfo;
        break;
      }
    }
  }
  if (currInfo) {
    currInfo.startLocal =
      currInfo.start && !currInfo.startLocal ? utcISOToLocalDateISO(currInfo.start) : null;
    currInfo.endLocal =
      currInfo.end && !currInfo.endLocal ? utcISOToLocalDateISO(currInfo.end) : null;
  }
  return currInfo;
};

export const markListingAsBroken = async (listingId: number) => {
  const url = `${process.env.NEXT_PUBLIC_API_HOST}/listings/${listingId}/mark_as_broken/`;
  try {
    await apiRequest('POST', url, null, null);
  } catch (error) {
    console.error(error);
  }
};

export const getListingImage = (listingImages: ListingImage[] | undefined, useDefault = true) => {
  // Return the first image that is not a video
  if (!listingImages && useDefault) {
    return DEFAULT_IMAGE;
  }

  const images = listingImages ?? [];
  const sortedImages = images.sort((a, b) => a.position - b.position);
  const videoExtensions = ['.mp4', '.avi', '.mov', '.flv', '.wmv'];
  for (const image of sortedImages) {
    if (!videoExtensions.some((ext) => image?.image?.toLowerCase().endsWith(ext))) {
      return image.image;
    }
  }

  if (useDefault) return DEFAULT_IMAGE;

  return null;
};

export const hasValidPaymentForCategory = (
  categoryPayments: CategoryPayment[] | undefined,
  currentCategoryId: number | undefined,
): boolean => {
  if (!categoryPayments || !currentCategoryId) return false;

  return categoryPayments.some((payment) => payment.category === currentCategoryId);
};

export const getSoldOutMessage = (
  category?: BasicCategory | Category | RootCategory | CategoryPreview | null,
) => {
  if (category?.multiple_variants) {
    return 'SOLD OUT';
  }
  return 'SOLD';
};

export const filterHiddenAttributes = (
  listing_attribute_value: BasicAttributeValue[] | undefined,
) => {
  if (listing_attribute_value) {
    const filteredListingAttribs = listing_attribute_value.filter((value) => {
      // Hide location attribute for now since it is handled separately
      if (value?.attribute?.name === 'Location') {
        return false;
      }

      return value?.attribute?.display_attribute;
    });
    return filteredListingAttribs.sort((a, b) => a.attribute.position - b.attribute.position);
  }
  return [];
};

export const onImageError =
  (listing: Listing, setImageError: React.Dispatch<React.SetStateAction<boolean>>) =>
  (_event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    // Expire candiate facebook listings that have broken image links
    if (
      listing?.id &&
      listing?.source?.toLowerCase() === 'facebook' &&
      listing?.status !== 'active'
    ) {
      markListingAsBroken(listing.id);
      setImageError(true);
    }
  };

export const getListingSlug = (title: string, listingId: number) => {
  const slug = slugify(title, {
    lower: true,
    strict: true,
  });
  return `${slug.substring(0, 32).trim()}-${listingId}`;
};
